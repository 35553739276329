import BaseController from "./BaseController";

export interface Location {
    id?: number
    name: string
    medical_queue_indicator?: boolean
    show_in_covidpass?: boolean
    times?: LocationTimes[]
}
export interface LocationTimes {
    location_id: number
    day_of_week: string
    open_time: any
    close_time: any
    is_open: boolean
}

export interface LotResponse {
    id?: string;
    company: string;
    lot_number: string;
    flushot: boolean;
}

export interface PrintersResponse {
    id: string;
    name: string;
}

export default class CheckinApi extends BaseController {
    readonly url_lots: string
    readonly url_locations: string
    readonly url_printers: string

    constructor() {
        super()
        this.url_locations = `${this.apiHost}/${this.apiPathMedical}/checkin/locations`
        this.url_lots = `${this.apiHost}/${this.apiPathMedical}/medical/shots/lots`
        this.url_printers = `${this.apiHost}/${this.apiPathMedical}/checkin/printers`
    }

    async fetchLocations(): Promise<Location[]> {
        let response = await this.useFetch('GET', 'fetchLocations', this.url_locations);
        if (response.status === 200) {
            return response.json();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async createLocations(body: Location): Promise<any> {
        let response = await this.useFetch('POST', 'createLocations', this.url_locations, body);
        if (response.status === 200) {
            return true;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async updateLocations(body: Location): Promise<any> {
        let response = await this.useFetch('PUT', 'updateLocations', this.url_locations, body);
        if (response.status === 200) {
            return true;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async deleteLocations(id: number): Promise<any> {
        let response = await this.useFetch('DELETE', 'deleteLocations', `${this.url_locations}/${id}`);
        if (response.status === 200) {
            return true;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async fetchLots(flushot?: boolean): Promise<LotResponse[]> {
        let response = await this.useFetch('GET', 'fetchLots', `${this.url_lots}${flushot ? "?is_flu_shot=1" : ""}`);
        if (response.status === 200) {
            return response.json();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async createLots(lot: LotResponse): Promise<any> {
        let response = await this.useFetch('POST', 'createLots', `${this.url_lots}`, lot);
        if (response.status === 200 || response.status === 201) {
            return response.json();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async updateLots(lot: LotResponse): Promise<any> {
        let response = await this.useFetch('PUT', 'updateLots', `${this.url_lots}/${lot?.id}`, lot);
        if (response.status === 200 || response.status === 201) {
            return response.json();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async deleteLots(id: string): Promise<any> {
        let response = await this.useFetch('DELETE', 'deleteLots', `${this.url_lots}/${id}`);
        if (response.status === 200 || response.status === 204) {
            return true
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async fetchPrinters(): Promise<PrintersResponse[]> {
        let response = await this.useFetch('GET', 'fetchPrinters', `${this.url_printers}`);
        if (response.status === 200) {
            return response.json();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
}