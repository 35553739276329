import React, { Fragment, useState } from "react";

import { useAppContext } from "../../context/AppContext";
import {
	ActionList,
	AtlasPage,
	BasicLayout,
	Button,
	ButtonBar,
	ButtonBarType,
	ButtonState,
	ButtonType,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	DropdownItemProps,
	DropDownState,
	Icon,
	ListItemProps,
	NavBar,
	NavBarTypes,
	NavItemProps,
	NavItemState,
	Profile,
	ReleaseNoteItemProps,
	ReleaseNotes,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Text,
	TextType,
} from "@mit/hui";
import PersonApi from "../../api/controller/PersonApi";
import LotList from "../lot/LotList";
import ScannedContainer from "./ScannedContainer";
import EditLots from "../lot/EditLots";
import EditCheckinPerson from "../checkin_person/EditCheckinPerson";
import EditLocation from "../location/EditLocation";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface Props {}
const personApi = new PersonApi();

const Component: React.FC<Props> = () => {
	const {
		profilePicture,
		profile,
		isLoading,
		auth,
		setIsLoading,
		selectedLot,
		setSelectedPerson,
		userId,
		setUserId,
		setSuccessMessage,
		selectedLocation,
		locations,
		setSelectedLot,
		selectedCheckinPerson,
		checkinPersons,
		setSelectedCheckinPerson,
		setSelectedLocation,
		selectedPage,
		setSelectedPage,
		setSelectedPrinter,
		selectedPrinter,
		printers,
		showEditLots,
		setShowEditLots,
		showEditAdministerer,
		setShowEditAdministerer,
		showEditLocation,
		setShowEditLocation,
		setLocations,
		setCheckinPersons
	}: any = useAppContext();
	const [startupSteps, setStartupSteps] = useState<"Medical Checkin" | "Select Printer" | "Select Company & Lot" | "Select Location" | "Select Administerer" | "Confirm">("Medical Checkin");
	const [flushot, setFlushot] = useState<boolean>(false);
	const [showStartUpModal, setStartUpModal] = useState<boolean>(true);
	const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false);

	const subMenuData: DropdownItemProps[] = [
		{
			icon: "sign-out",
			text: "Logout",
			state: DropDownState.None,
			onClick: () => {
				auth.logout().then((data) => {});
			},
		},
	];

	const navData: NavItemProps[] = [
		{
			icon: "syringe",
			text: "Flu Shot",
			iconOnly: false,
			onClick: () => {
				resetSelectedResults();
				setSelectedPage("Flu Shot");
				setStartupSteps("Select Company & Lot");
				setStartUpModal(true);
				setFlushot(true);
			},
			state: selectedPage === "Flu Shot" ? NavItemState.Active : NavItemState.None,
		},
		{
			icon: "shield-check",
			text: "Vaccine",
			iconOnly: false,
			onClick: () => {
				resetSelectedResults();
				setSelectedPage("Vaccine");
				setStartupSteps("Select Company & Lot");
				setStartUpModal(true);
				setFlushot(false);
			},
			state: selectedPage === "Vaccine" ? NavItemState.Active : NavItemState.None,
		},
		//{
		//	icon: "vial",
		//	text: "COVID Test",
		//	iconOnly: false,
		//	onClick: () => {
		//		resetSelectedResults();
		//		setSelectedPage("COVID Test");
		//		setStartupSteps("Select Printer");
		//		setStartUpModal(true);
		//	},
		//	state: selectedPage === "COVID Test" ? NavItemState.Active : NavItemState.None,
		//},
	];

	const navUtil: NavItemProps[] = [
		{
			icon: "question-circle",
			text: "Contact Support",
			iconOnly: false,
			onClick: () => {
				window.location.href = "mailto:covidapps-help@mit.edu";
			},
		},
		{
			icon: "bell",
			text: "What's new!",
			iconOnly: false,
			onClick: () => {
				setShowWhatsNew(true);
			},
			// state: selectedPage === "Flu Shot" ? NavItemState.Active : NavItemState.None,
		},
	];

	const releaseNotes: ReleaseNoteItemProps[] = [
		{
			date: "11/18/2020",
			text: "First release of COVD Travel with basic functionality to log travel plans and integration with COVIDPass Holds.",
			items: [],
		},
	];

	const onPressSearch = () => {
		setSelectedPerson(null);
		setSuccessMessage(null);
		(async () => {
			setIsLoading((state: any) => ({ ...state, search: true }));

			if (selectedPage === "COVID Test") {
				//peopleApi.getPersonInfo(userId, selectedLocation.id);
				//checkinProvider.handlePrintLabel({}, false);
			}

			personApi.fetchProfile(userId).then(
				(profile) => {
					setIsLoading((state: any) => ({ ...state, search: false }));
					setSelectedPerson(profile);
				},
				() => {
					setIsLoading((state: any) => ({ ...state, search: false }));
				}
			);
		})();
	};

	const resetSelectedResults = () => {
		setSelectedPerson(null);
		setSelectedPrinter(null);
		setSelectedLocation(null);
		setSelectedLot(null);
		setSelectedCheckinPerson(null);
	};

	const onInputChange = (event: any) => {
		setSelectedPerson(null);
		let value = event.target.value;
		setUserId(value);
		if (parseInt(value).toString().length === 9) {
			setSelectedPerson(null);
			setSuccessMessage(null);
			(async () => {
				setIsLoading((state: any) => ({ ...state, search: true }));

				if (selectedPage === "COVID Test") {
					//peopleApi.getPersonInfo(userId, selectedLocation.id);
					//checkinProvider.handlePrintLabel({}, false);
				}

				personApi.fetchProfile(value).then(
					(profile) => {
						setIsLoading((state: any) => ({ ...state, search: false }));
						setSelectedPerson(profile);
					},
					() => {
						setIsLoading((state: any) => ({ ...state, search: false }));
					}
				);
			})();
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onPressSearch();
		}
	};

	const textboxData = {
		name: "searchbox",
		placeholderText: "Barcode / MIT ID / Kerb",
		startIcon: "barcode",
		endIconRequired: true,
		value: userId,
		isBusy: isLoading.search,
		onEndIconClick: (event: any) => {
			onPressSearch();
		},
		onKeyUp: handleKeyDown,
		onChange: onInputChange,
	};

	const getLocationItems = () => {
		let locationItems: ListItemProps[] = locations.map((itm) => {
			return {
				text: itm.name,
				icon: "map-marker",
				onClick: () => {
					setSelectedLocation(itm);

					if (selectedPage !== "COVID Test") setStartupSteps("Select Administerer");
					else setStartupSteps("Confirm");
				},
			};
		});

		return locationItems;
	};

	const getPrinterItems = () => {
		let printerItems: ListItemProps[] = printers.map((itm) => {
			return {
				text: itm.name,
				icon: "print",
				onClick: () => {
					setSelectedPrinter(itm);
					setStartupSteps("Select Location");
				},
			};
		});

		return printerItems;
	};

	const getCheckinPersonsItems = () => {
		let locationItems: ListItemProps[] = checkinPersons.map((itm) => {
			return {
				text: itm.full_name,
				secondaryText: itm.mit_id,
				icon: "user-nurse",
				onClick: () => {
					setSelectedCheckinPerson(itm);
					setStartupSteps("Confirm");
				},
			};
		});

		return locationItems;
	};

	return (
		<div className="main">
			{/* <div className='left-container'>
                <img src="./assets/atlas-logo.svg" alt="Atlas Icon" />
            </div>
            <div className='right-container'>
                <Header />
                <ScannedContainer />
            </div> */}
			<AtlasPage
				name="Medical Checkin"
				environment={process.env.ENV === "prod" ? "production" : "dev"}
				utilityNavigation={navUtil}
				subtitle=""
				theme="medical"
				gaId=""
				profile={<Profile name={profile?.display_name} subtitle={profile?.department} imageUrl={profilePicture} submenu={subMenuData} />}
				navigation={<NavBar tabs={navData} type={NavBarTypes.IconText} />}
				content={
					<BasicLayout
						startState={true}
						fullWidth={true}
						search={textboxData}
						content={
							<Fragment>
								<div>
									<div className={"row"}>
										<div className="col-lg-10 mx-auto text-center btn-padded">
											{selectedLot?.company && (
												<Button
													onClick={() => {
														setStartupSteps("Select Company & Lot");
														setStartUpModal(true);
													}}
													text={`${selectedLot?.company} ${selectedLot.lot_number}`}
													type={ButtonType.Secondary | ButtonType.Ghost}
													icon="vials"
												/>
											)}
											{selectedPrinter?.name && (
												<Button
													onClick={() => {
														setStartupSteps("Select Printer");
														setStartUpModal(true);
													}}
													text={`${selectedPrinter.name}`}
													type={ButtonType.Secondary | ButtonType.Ghost}
													icon="print"
												/>
											)}
											{selectedLocation?.name && (
												<Button
													onClick={() => {
														setStartupSteps("Select Location");
														setStartUpModal(true);
													}}
													text={selectedLocation.name}
													type={ButtonType.Secondary | ButtonType.Ghost}
													icon="map-marker"
												/>
											)}
											{selectedCheckinPerson?.full_name && (
												<Button
													onClick={() => {
														setStartupSteps("Select Administerer");
														setStartUpModal(true);
													}}
													text={selectedCheckinPerson?.full_name}
													type={ButtonType.Secondary | ButtonType.Ghost}
													icon="user-nurse"
												/>
											)}
											{selectedLot?.company && (
												<DropdownButton
													variant="outline-secondary"
													className=""
													style={{ display: "contents" }}
													id={`template`}
													drop={"down"}
													title={<Icon icon="cog" type="regular" />}>
													<Dropdown.Item eventKey="1" onClick={() => setShowEditLots(true)}>
														Edit Companies and Lots
													</Dropdown.Item>
													<Dropdown.Item eventKey="2" onClick={() => setShowEditLocation(true)}>
														Edit Locations
													</Dropdown.Item>
													<Dropdown.Item eventKey="2" onClick={() => setShowEditAdministerer(true)}>
														Edit Administerer
													</Dropdown.Item>
												</DropdownButton>
											)}
										</div>
									</div>
								</div>
								<ScannedContainer />
							</Fragment>
							// <>
							// 	{errorText && <Alert type="error" text={errorText} />}
							// 	<Header />
							// 	<ScannedContainer />
							// </>
						}
						contentTitle={selectedPage}
					/>
				}
			/>
			<TemplateModal
				size={TemplateModalSize.Default}
				autoHeight
				theme="medical"
				body={
					<div>
						{startupSteps === "Medical Checkin" && (
							<div className="row text-center">
								{
									// Temporary comment out as requested by Covid Meeting
								}
								{
									<div className="col-4">
										{
											// eslint-disable-next-line
										}
										<button
											className="btn btn-big-icon"
											onClick={() => {
												setSelectedPage("Vaccine");
												setStartupSteps("Select Company & Lot");
											}}>
											<i className="fas fa-shield-check" style={{ color: "#00B60D" }}></i>
											<strong>Covid Vaccine</strong>
										</button>
									</div>
								}
								<div className="col-4">
									{
										// eslint-disable-next-line
										<button
											className="btn btn-big-icon"
											onClick={() => {
												setFlushot(true);
												setSelectedPage("Flu Shot");
												setStartupSteps("Select Company & Lot");
											}}>
											<i className="fas fa-syringe" style={{ color: "#008CDE" }}></i>
											<strong>Flu Shot</strong>
										</button>
									}
								</div>
								<div className="col-4 d-none">
									{
										// eslint-disable-next-line
										<a
											className="btn btn-big-icon"
											onClick={() => {
												setSelectedPage("COVID Test");
												setStartupSteps("Select Printer");
											}}>
											<i className="fas fa-vial" style={{ color: "#007A82" }}></i>
											<strong>Covid Test</strong>
										</a>
									}
								</div>

								<div className="col-4">
									{
										// eslint-disable-next-line
										<button className="btn btn-big-icon disabled">
											<i className="fas fa-vial" style={{ color: "#007A82" }}></i>
											<strong>Covid Test</strong>
										</button>
									}
								</div>
							</div>
						)}
						{startupSteps === "Select Printer" && (
							<div>
								<ActionList items={getPrinterItems()} />
							</div>
						)}
						{startupSteps === "Select Company & Lot" && (
							<div>
								<LotList
									flushot={flushot}
									onEdit={() => {
										setShowEditLots(true);
										setStartUpModal(false);
									}}
									onSelect={(itm) => {
										setSelectedLot(itm);
										setStartupSteps("Select Location");
									}}
								/>
							</div>
						)}
						{startupSteps === "Select Location" && (
							<div>
								<div className="d-flex d-flex justify-content-center pb-2">
									<Button
										onClick={() => {
											setShowEditLocation(true);
											setStartUpModal(false);
										}}
										icon="edit"
										type={ButtonType.TextNaked}
										text="Edit Locations"
									/>
								</div>
								<ActionList items={getLocationItems()} />
							</div>
						)}
						{startupSteps === "Select Administerer" && (
							<div>
								<div className="d-flex d-flex justify-content-center pb-2">
									<Button
										onClick={() => {
											setShowEditAdministerer(true);
											setStartUpModal(false);
										}}
										icon="edit"
										type={ButtonType.TextNaked}
										text="Edit Administerer"
									/>
								</div>
								<ActionList items={getCheckinPersonsItems()} />
							</div>
						)}
						{startupSteps === "Confirm" && (
							<div>
								<p>Are you sure you want to proceed with</p>
								<p>
									<strong>{selectedPage}</strong>
								</p>
								{selectedLot && selectedLot.company && (
									<p>
										<i className="fal fa-vials"></i>&nbsp;{`${selectedLot?.company} ${selectedLot.lot_number}`}
									</p>
								)}
								{selectedCheckinPerson && selectedCheckinPerson.full_name && (
									<p>
										<i className="fal fa-user-nurse"></i>&nbsp;{selectedCheckinPerson?.full_name}
									</p>
								)}
								{selectedPrinter && selectedPrinter.name && (
									<p>
										<i className="fal fa-print"></i>&nbsp;{selectedPrinter.name}
									</p>
								)}
								<p>
									<i className="fal fa-map-marker"></i>&nbsp;{selectedLocation?.name}
								</p>
								<ButtonBar
									type={ButtonBarType.Padded}
									buttons={[
										{
											text: "Go Back",
											icon: "chevron-left",
											onClick: () => {
												resetSelectedResults();
												setStartupSteps("Medical Checkin");
												setFlushot(false);
											},
											type: ButtonType.Secondary | ButtonType.Ghost,
											state: ButtonState.Enabled,
										},
										{ text: "Yes, I'm sure", icon: "check", onClick: () => setStartUpModal(false), type: ButtonType.Primary, state: ButtonState.Enabled },
									]}
								/>
							</div>
						)}
					</div>
				}
				bodyAlignment={TemplateModalAlignment.Center}
				footer={<div></div>}
				header={<Text content={startupSteps} type={TextType.Heading4} />}
				name="startupModal"
				show={showStartUpModal}
			/>
			<Drawer
				show={showWhatsNew}
				onClose={() => setShowWhatsNew(false)}
				type={DrawerType.Right}
				size={DrawerSize.Medium}
				layout={DrawerLayout.Hero}
				themedColor={"medical-dark"}
				header={<Text content="What's New!" type={TextType.Heading4} icon="bell" />}
				contents={
					<Fragment>
						<ReleaseNotes items={releaseNotes} />
					</Fragment>
				}
				footer={[]}
			/>
			<Drawer
				show={showEditLots}
				onClose={() => {
					setShowEditLots(false);
					if (!selectedLot?.company) {
						setStartupSteps("Select Company & Lot");
						setStartUpModal(true);
					}
				}}
				type={DrawerType.Right}
				size={DrawerSize.Small}
				layout={DrawerLayout.Hero}
				themedColor={"medical"}
				header={<Text content="Edit lots" type={TextType.Heading4} />}
				contents={
					<Fragment>
						<EditLots flushot={flushot} />
					</Fragment>
				}
				footer={[]}
			/>
			<Drawer
				show={showEditAdministerer}
				onClose={() => {
					setShowEditAdministerer(false);
					if (!selectedCheckinPerson?.full_name) {
						setStartupSteps("Select Administerer");
						setStartUpModal(true);
					}
				}}
				type={DrawerType.Right}
				size={DrawerSize.Small}
				layout={DrawerLayout.Hero}
				themedColor={"medical"}
				header={<Text content="Edit Administerer" type={TextType.Heading4} />}
				contents={
					<Fragment>
						<EditCheckinPerson onRefreshPerson={(persons) => setCheckinPersons(persons)} />
					</Fragment>
				}
				footer={[]}
			/>
			<Drawer
				show={showEditLocation}
				onClose={() => {
					setShowEditLocation(false);
					if (!selectedCheckinPerson?.full_name) {
						setStartupSteps("Select Location");
						setStartUpModal(true);
					}
				}}
				type={DrawerType.Right}
				size={DrawerSize.Small}
				layout={DrawerLayout.Hero}
				themedColor={"medical"}
				header={<Text content="Edit Location" type={TextType.Heading4} />}
				contents={
					<Fragment>
						<EditLocation onRefreshLocations={(locations) => setLocations(locations)}/>
					</Fragment>
				}
				footer={[]}
			/>
		</div>
	);
};

export default Component;
