import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from './context/AppContext';
import App from './app';
import Logout from './components/Logout';
import './scss/main.scss';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


const RootComponent = () => {
  return (<Fragment>
    <Router>
      <Switch>
        <Route path="/" component={App} exact />
        <Route path="/logout" component={Logout} exact />
      </Switch>
    </Router>
  </Fragment>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AppProvider>
        <RootComponent />
      </AppProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


