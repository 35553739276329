import BaseController from "./BaseController";

export interface VaccineStatusResponse {
	course_guid: string;
	course_progress: VaccineCourse[];
	eligible: boolean;
	can_checkin: boolean;
	has_appointment: boolean;
	eligible_bypass: boolean;
	shot_days_interval: string;
	end_date: string;
	number_of_shots: any;
	start_date: string;
	status: string;
	status_message: string;
	medical_messages: string[]
}

export interface VaccineCourse {
	mit_id: string;
	shot_number: number;
	status: string;
	shot_date: string;
	lot_number: string;
	lot_company: string;
	body_region: number;
	source: string;
	test_by_mit_id: string;
	location_id: string;
}

export interface VaccineQuestions {
	answer: boolean
	disqualify: boolean
	follow_up_multiple: boolean
	follow_up_questions: Array<any>
	follow_up_trigger: Array<any>
	id: number
	more_text: string
	page: number
	required: boolean
	short_text: string
	style: string
	text: string
}

export interface ShotRequestPayload {
	vaccine_shot_guid?: string;
	vaccine_course_guid?: string | null;
    mit_id: string;
    shot_number: number;
    status?: string;
    shot_date: string;
    lot_number: string;
    lot_company: string;
    body_region: number;
    source?: string;
    test_by_mit_id: string;
	location_id: number;
	active?: boolean;
}

export default class VaccineApi extends BaseController {
    readonly url_fetch_status: string;
	readonly url_post_shot: string;
	readonly url_fetch_course: string;
	readonly url_fetch_questions: string;

	constructor() {
		super();
		this.url_fetch_status = `${this.apiHost}/${this.apiPathVaccine}/vaccine/status`;
		this.url_fetch_course = `${this.apiHost}/${this.apiPathVaccine}/vaccine/course`;
		this.url_fetch_questions = `${this.apiHost}/${this.apiPathVaccine}/vaccine/questions`;
        this.url_post_shot = `${this.apiHost}/${this.apiPathVaccine}/vaccine/shot`;
	}

	async fetchVaccineStatus(id: string): Promise<VaccineStatusResponse> {
		let response = await this.useFetch("GET", "fetchVaccineStatus", this.url_fetch_status + "?mit_id=" + id);
		if (response.status === 200) {
			return response.json();
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}
	}
	
	async fetchVaccineCourse(id: string): Promise<VaccineCourse[]> {
		let response = await this.useFetch("GET", "fetchVaccineCourse", this.url_fetch_course + "?mit_id=" + id);
		if (response.status === 200) {
			return response.json();
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}
	}
	
	async fetchVaccineQuestions(id: string): Promise<VaccineQuestions[]> {
		let response = await this.useFetch("GET", "fetchVaccineQuestions", this.url_fetch_questions + "?mit_id=" + id);
		if (response.status === 200) {
			return response.json();
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}
    }
    
    postVaccineShot = async (body: ShotRequestPayload): Promise<any> => {
        let response = await this.useFetch('POST', 'postVaccineShot', this.url_post_shot, body);

        if (response.status !== 200 && response.status !== 201) {
            let text = await response.json();
            return Promise.reject(text);
        }

        return true;
    }
}
