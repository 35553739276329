import * as React from "react";
import { FC, useState, useEffect } from "react";
import { ActionList, Button, ButtonType, Error, ErrorContext, ErrorType, ListItemProps } from "@mit/hui";
import CheckinApi, { LotResponse } from "../../api/controller/CheckinApi";

const checkinApi = new CheckinApi();

interface LotListProps {
	flushot: boolean;
	onSelect: (itm: LotResponse) => void;
	onEdit: () => void;
}

const LotList: FC<LotListProps> = ({ flushot, onSelect, onEdit }) => {
	const [isLoading, setLoading] = useState(true);
	const [isError] = useState(false);
	const [lots, setLots] = useState<LotResponse[]>([]);

	const fetchLots = () => {
		setLoading(true);
		checkinApi.fetchLots(flushot).then((response) => {
			if (response) {
				setLoading(false);
				setLots(response);
			}
		});
	};

	useEffect(() => {
		fetchLots();
		// eslint-disable-next-line
	}, []);

	const getLotItems = () => {
		let items: ListItemProps[] = lots.map((itm) => {
			return {
				text: itm.company,
				secondaryText: itm.lot_number,
				icon: "vials",
				onClick: () => {
					onSelect(itm);
				},
			};
		});

		return items;
	};

	if (isError) return <Error context={ErrorContext.Component} message="Oops.. something went wrong" type={ErrorType.Generic} />;

	return (
		<React.Fragment>
			{isLoading && <ActionList items={[]} isLoading />}
			{!isLoading && (
				<React.Fragment>
					<div className="d-flex d-flex justify-content-center pb-2">
						<Button onClick={onEdit} icon="edit" type={ButtonType.TextNaked} text="Edit Companies & Lots" />
					</div>
					<ActionList items={getLotItems()} />
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default LotList;
