import React, { useState, useEffect } from "react";
import { Loader } from "@mit/hui";
import { useAppContext } from "./context/AppContext";
import AuthProvider from "./api/AuthProvider";
import BaseController from "./api/controller/BaseController";
import CheckinApi from "./api/controller/CheckinApi";
import FluShotsApi from "./api/controller/FluShotsApi";
import MainContainer from "./components/container/MainContainer";

const authProvider = new AuthProvider();
const baseController = new BaseController();
const checkinApi = new CheckinApi();
const fluShotsApi = new FluShotsApi();

const App: React.FC = () => {
	const [isLoading, setIsLoading]: any = useState(true);
	const [setErrors]: any = useState({});
	const { initializeApp, initializeProfile, initializeProfilePicutre, setLocations, setBodyRegions, setLocationLots, setCheckinPersons }: any = useAppContext();

	useEffect(
		() => {
			(async () => {
				setIsLoading(true);
				// Load Profile
				try {
					const [profile, profilePicture] = await Promise.all([baseController.getProfile(), baseController.getProfilePicture()]);
					initializeProfile(profile);
					initializeProfilePicutre(profilePicture);
				} catch (e) {
					console.error(e);
					setErrors((prev: any) => ({ ...prev, profile: true }));
				}
				// Initialize application/lookups
				try {
					let userName = await authProvider.getToken();
					initializeApp({ loggedInUser: userName });

					const [locationResult, bodyRegionResults, checkinPersonsResult, checkinLotResult] = await Promise.all([
						checkinApi.fetchLocations(),
						fluShotsApi.fetchFluShotsBodyRegions(),
						fluShotsApi.fetchFluShotsCheckinPersons(),
						fluShotsApi.fetchFluShotsCheckinLots(),
					]);

					setLocations(locationResult);
					setBodyRegions(bodyRegionResults);
					setLocationLots(checkinLotResult);
					setCheckinPersons(checkinPersonsResult);
					setIsLoading(false);
				} catch (e) {
					console.error(e);
					setIsLoading(false);
					// setErrors((prev: any) => ({ ...prev, initialization: true }));
					//TODO: Some kind of error handling...
				}
			})();
		},
		// eslint-disable-next-line
		[]
	);

	if (isLoading) {
		return <Loader theme="medical" contactEmail="nist-physec@mit.edu" exit={!isLoading} name={"Covid Medical Checkin"} />;
	}
	// else if (!auth.isMedicalCheckinAdmin && !auth.isCheckinView && !auth.isWaitTimeCheckin) {
	//     return (
	//         <Loader theme="medical" contactEmail="nist-physec@mit.edu" hasAccess={false} exit={!isLoading} name={"Covid Medical Checkin"} />
	//     )
	// }
	else {
		return <MainContainer />;
	}
};

export default App;
