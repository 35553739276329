import * as React from "react";
import { FC, useState, useEffect } from "react";
import FluShotsApi, { CheckinPerson } from "../../api/controller/FluShotsApi";
import { ActionList, Form, FormContainer, ListItem, FormField, Button, ButtonType, ButtonState } from "@mit/hui";

const fluShotsApi = new FluShotsApi();

interface EditCheckinPersonProps {
	onRefreshPerson: (persons: CheckinPerson[]) => void
}

const EditCheckinPerson: FC<EditCheckinPersonProps> = ({onRefreshPerson}) => {
	const [isLoading, setLoading] = useState(true);
	const [isBusy, setBusy] = useState(false);
	const [isRemoving, setRemoving] = useState(false);
	const [checkinPersons, setCheckinPersons] = useState<CheckinPerson[]>([]);
	const [selectedCheckinPerson, setSelectedCheckinPerson] = useState<CheckinPerson | null>();

	const fetchCheckinPersons = () => {
		setLoading(true);
		fluShotsApi.fetchFluShotsCheckinPersons().then((response) => {
			if (response) {
				setLoading(false);
				setCheckinPersons(response);
				onRefreshPerson(response);
			}
		});
	};

	useEffect(() => {
		fetchCheckinPersons();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{isLoading && <ActionList items={[]} isLoading />}
			{!isLoading && (
				<React.Fragment>
					{selectedCheckinPerson ? (
						<>
							<Form formValidationTrigger="onSubmit">
								{(methods) => {
									const onSave = () => {
										methods.trigger().then((valid) => {
											if (!valid) return;

											setBusy(true);
											var formData = methods.getValues();

											fluShotsApi
												.createFluShotsCheckinPersons(formData.mitId)
												.then(() => {
													setSelectedCheckinPerson(undefined);
													fetchCheckinPersons();
												})
												.finally(() => {
													setBusy(false);
												});
										});
									};

									const onRemove = () => {
										setRemoving(true);

										fluShotsApi
											.deleteFluShotsCheckinPersons(selectedCheckinPerson.mit_id!)
											.then(() => {
												setSelectedCheckinPerson(undefined);
												fetchCheckinPersons();
											})
											.finally(() => {
												setRemoving(false);
											});
									};

									return (
										<FormContainer formValidationTrigger="onSubmit" methods={methods} id="form-lots">
											<FormField
												readonly={selectedCheckinPerson.mit_id !== ""}
												placeholder="e.g. 945683476"
												label="MIT ID"
												id="mitId"
												editor="textbox"
												value={selectedCheckinPerson?.mit_id}
												required
											/>
											{selectedCheckinPerson.mit_id !== "" && (
												<FormField readonly placeholder="e.g. James Doe" label="Full name" id="fullName" editor="textbox" value={selectedCheckinPerson?.full_name} required />
											)}
											<div className="d-flex justify-content-between pt-3">
												<div className="btn-padded">
													<Button onClick={() => setSelectedCheckinPerson(undefined)} text="Back" type={ButtonType.Ghost | ButtonType.Secondary} icon="chevron-left" />
												</div>
												<div className="btn-padded">
													{selectedCheckinPerson.mit_id && (
														<Button
															state={isRemoving ? ButtonState.Disabled : ButtonState.Enabled}
															isBusy={isRemoving}
															onClick={() => {
																onRemove();
															}}
															type={ButtonType.Ghost | ButtonType.Secondary}
															icon="trash"
															overwriteColor="danger"	
															text={"Remove"}
														/>
													)}
													{selectedCheckinPerson.mit_id === "" && (
														<Button
															state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
															isBusy={isBusy}
															onClick={() => {
																onSave();
															}}
															text={"Add"}
														/>
													)}
												</div>
											</div>
										</FormContainer>
									);
								}}
							</Form>
						</>
					) : (
						<>
							<ListItem
								icon="plus"
								onClick={() => {
									setSelectedCheckinPerson({
										display_name: "",
										full_name: "",
										mit_id: "",
									});
								}}
								text="Add New"
							/>
							{checkinPersons.map((itm) => {
								return <ListItem onClick={() => setSelectedCheckinPerson(itm)} text={itm.display_name} secondaryText={itm.mit_id} />;
							})}					
						</>
					)}
				</React.Fragment>
			)}
		</>
	);
};

export default EditCheckinPerson;
