import { Alert, Error, ErrorContext, ErrorType } from "@mit/hui";
import React, { Fragment } from "react";
import { useAppContext } from "../context/AppContext";

interface Props {}

const Component: React.FC<Props> = () => {

    let { successMessage }: any = useAppContext();

	return (
		<Fragment>
            {successMessage && <Alert type={"success"} text={`${successMessage}`} />}
			<Error type={ErrorType.FirstAction} message={"Scan barcode or enter MIT ID / Kerb manually."} context={ErrorContext.Component} />
		</Fragment>
	);
	// return (
	//     <div className='waiting-to-scan'>
	//         {successMessage && <Alert variant="success">
	//             <p>
	//                 {successMessage}
	//             </p>
	//         </Alert>}
	//         <img src="./assets/info-circle.svg" alt="Info"/>
	//         <h4>Scan barcode or enter MIT ID / Kerb manually.</h4>
	//     </div>
	// );
};

export default Component;
