import * as React from "react";
import { ActionList, Form, FormContainer, ListItem, FormField, Button, ButtonType, ButtonState } from "@mit/hui";
import { FC, useState, useEffect } from "react";
import CheckinApi, { LotResponse } from "../../api/controller/CheckinApi";

const checkinApi = new CheckinApi();

interface EditLotsProps {
	flushot: boolean;
}

const EditLots: FC<EditLotsProps> = ({ flushot }) => {
	const [isLoading, setLoading] = useState(true);
	const [isBusy, setBusy] = useState(false);
	const [isRemoving, setRemoving] = useState(false);
	const [lots, setLots] = useState<LotResponse[]>([]);
	const [selectedLots, setSelectedLots] = useState<LotResponse | null>();

	const fetchLots = () => {
		setLoading(true);
		checkinApi.fetchLots(flushot).then((response) => {
			if (response) {
				setLoading(false);
				setLots(response);
			}
		});
	};

	useEffect(() => {
		fetchLots();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{isLoading && <ActionList items={[]} isLoading />}
			{!isLoading && (
				<React.Fragment>
					{selectedLots ? (
						<>
							<Form formValidationTrigger="onSubmit">
								{(methods) => {
									const onSave = () => {
										methods.trigger().then((valid) => {
											if (!valid) return;

											setBusy(true);
											var formData = methods.getValues();

											let body: LotResponse = {
												company: formData.company,
												flushot: flushot,
												lot_number: formData.lotNumber,
												id: selectedLots?.id,
											};

											if (selectedLots.id) {
												checkinApi
													.updateLots(body)
													.then(() => {
														setSelectedLots(undefined);
														fetchLots();
													})
													.finally(() => {
														setBusy(false);
													});
											} else {
												checkinApi
													.createLots(body)
													.then(() => {
														setSelectedLots(undefined);
														fetchLots();
													})
													.finally(() => {
														setBusy(false);
													});
											}
										});
									};

									const onRemove = () => {
										setRemoving(true);

										checkinApi
											.deleteLots(selectedLots.id!)
											.then(() => {
												setSelectedLots(undefined);
												fetchLots();
											})
											.finally(() => {
												setRemoving(false);
											});
									};

									return (
										<FormContainer formValidationTrigger="onSubmit" methods={methods} id="form-lots">
											<FormField placeholder="e.g. Moderna" label="Company" id="company" editor="textbox" value={selectedLots?.company} required />
											<FormField placeholder="e.g. 012L20A" label="Lot number" id="lotNumber" editor="textbox" value={selectedLots?.lot_number} required />
											<div className="d-flex justify-content-between pt-3">
												<div className="btn-padded">
													<Button onClick={() => setSelectedLots(undefined)} text="Back" type={ButtonType.Ghost | ButtonType.Secondary} icon="chevron-left" />
												</div>
												<div className="btn-padded">
													{selectedLots.id && (
														<Button
															state={isRemoving ? ButtonState.Disabled : ButtonState.Enabled}
															isBusy={isRemoving}
															onClick={() => {
																onRemove();
															}}
															type={ButtonType.Ghost | ButtonType.Secondary}
															icon="trash"		
															overwriteColor="danger"											
															text={"Remove"}
														/>
													)}
													<Button
														state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
														isBusy={isBusy}
														onClick={() => {
															onSave();
														}}
														text={selectedLots?.id ? "Update" : "Add"}
													/>
												</div>
											</div>
										</FormContainer>
									);
								}}
							</Form>
						</>
					) : (
						<>
							<ListItem
								icon="plus"
								onClick={() => {
									setSelectedLots({
										company: "",
										flushot: flushot,
										lot_number: "",
									});
								}}
								text="Add New"
							/>
							{lots.map((itm) => {
								return <ListItem onClick={() => setSelectedLots(itm)} text={itm.company} secondaryText={itm.lot_number} />;
							})}
						</>
					)}
				</React.Fragment>
			)}
		</>
	);
};

export default EditLots;
