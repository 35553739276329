import BaseController from './BaseController';

export interface PersonProfile {
    mitId: string;
    kerberosId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    phone: string;
}

export interface Address {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
}
export interface ExtendedPersonProfile {
    mit_id: string;
    krb_name: string;
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
    gender?: string;
    date_of_birth?: string;
    type?: string;
    address: Address;
}

export default class PersonApi extends BaseController {
    readonly url_picture: string;
    readonly url_profile: string;
    readonly url_people: string;
    readonly url_person_extended: string;

    constructor() {
        super()
        this.url_picture = `${this.apiHost}/${this.apiPathDigitalId}/picture`;
        this.url_profile = `${this.apiHost}/${this.apiPathDigitalId}/profile`;
        this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search`;
        this.url_person_extended = `${this.apiHost}/${this.apiPathVaccine}/person`;
    }

    fetchProfile = async (kerbId?: string): Promise<PersonProfile> => {
        let urlAppend = "";
        if(kerbId && kerbId !== '' )
            urlAppend = "/"+kerbId

        let response = await this.useFetch('GET', 'fetchProfile', this.url_profile + urlAppend);
        if (response.status === 200) {
            try {
                let data = await response.json()
                return {
                    kerberosId: data.kerberos,
                    mitId: data.mitid,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    displayName: data.display_name,
                    email: data.email,
                    phone: data.phone_number
                }
            } catch (e){
                return Promise.reject("person not found");
            }
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
    fetchProfileExtended = async (mit_id: string | number): Promise<ExtendedPersonProfile> => {
        let urlAppend = "/"+mit_id

        let response = await this.useFetch('GET', 'fetchProfileExtended', this.url_person_extended + urlAppend);
        if (response.status === 200) {
            let data = await response.json()
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    fetchPicture = async (mitId?: string): Promise<string> => {
        let urlAppend = "";
        if(mitId && mitId !== '' )
            urlAppend = "/"+mitId+"?resolution=high"

        let response = await this.useFetch('GET', 'fetchPicture', this.url_picture + urlAppend);
        if (response.status === 200) {
            let data = await response.blob()
            return URL.createObjectURL(data)
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    fetchProfiles = async (query: string): Promise<PersonProfile[]> => {
        let response = await this.useFetch('GET', 'fetchProfiles', this.url_profile + query);
        if (response.status === 200) {
            let people = await response.json()
            return people.map((person) => {
                return {
                    kerberosId: person.kerberos,
                    mitId: person.mitid,
                    firstName: person.first_name,
                    lastName: person.last_name,
                    displayName: person.display_name,
                    email: person.email,
                    phone: person.phone_number
                }
            });
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
}
