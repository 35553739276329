import React from "react";

import { useAppContext } from "../../context/AppContext";

import WaitingToScan from "../WaitingToScan";
import PersonProfile from "../search/PersonProfile";

interface Props {}

const ScannedContainer: React.FC<Props> = () => {
	const { selectedLocation, selectedCheckinPerson, selectedPerson, selectedLot, selectedPage, selectedPrinter }: any = useAppContext();

	const DisplayComponent = () => {
		//Vaccine & Flu shot
		if (selectedPage !== "COVID Test" && !selectedLocation && !selectedLot && !selectedCheckinPerson) {
			return <div></div>;
		}

		//Covid Test
		if (selectedPage === "COVID Test" && !selectedLocation && !selectedPrinter) {
			return <div></div>;
		}

		if (!selectedPerson) {
			return <WaitingToScan />;
		}

		return <PersonProfile />;
	};

	return (
		<div className="user-info">
			{/* <Loading loaded={isLoading.search} /> */}
			<DisplayComponent />
		</div>
	);
};

export default ScannedContainer;
