import * as React from "react";
import { FC, useState, useEffect } from "react";
import { ActionList, Form, FormContainer, ListItem, FormField, Button, ButtonType, ButtonState } from "@mit/hui";
import CheckinApi, { Location } from "../../api/controller/CheckinApi";
import { useAppContext } from "../../context/AppContext";

const checkinApi = new CheckinApi();

interface EditLocationProps {
	onRefreshLocations: (locations: Location[]) => void
}

const EditLocation: FC<EditLocationProps> = ({
	onRefreshLocations
}) => {
	const [isLoading, setLoading] = useState(true);
	const [isBusy, setBusy] = useState(false);
	const [locations, setLocalLocations] = useState<Location[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<Location | null>();

	const fetchLocations = () => {
		setLoading(true);
		checkinApi.fetchLocations().then((response) => {
			if (response) {
				setLoading(false);
				setLocalLocations(response);
				onRefreshLocations(response);
			}
		});
	};

	useEffect(() => {
		fetchLocations();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{isLoading && <ActionList items={[]} isLoading />}
			{!isLoading && (
				<React.Fragment>
					{selectedLocation ? (
						<>
							<Form formValidationTrigger="onSubmit">
								{(methods) => {
									const onSave = () => {
										methods.trigger().then((valid) => {
											if (!valid) return;

											setBusy(true);
											var formData = methods.getValues();

											let body: Location = {
												name: formData.name,
												medical_queue_indicator: false,
												show_in_covidpass: false,
												id: formData.id ?? selectedLocation?.id,
											};

											checkinApi
												.updateLocations(body)
												.then(() => {
													setSelectedLocation(undefined);
													fetchLocations();
												})
												.finally(() => {
													setBusy(false);
												});
										});
									};

									return (
										<FormContainer formValidationTrigger="onSubmit" methods={methods} id="form-lots">
											<FormField
												readonly={selectedLocation.id ? true : false}
												properties={{
													numericOnly: true,
												}}
												placeholder="e.g. 42"
												label="ID"
												id="id"
												editor="textbox"
												value={selectedLocation?.id}
											/>
											<FormField placeholder="e.g. Building 42" label="Name" id="name" editor="textbox" value={selectedLocation?.name} required />
											<div className="d-flex justify-content-between pt-3">
												<div className="btn-padded">
													<Button onClick={() => setSelectedLocation(undefined)} text="Back" type={ButtonType.Ghost | ButtonType.Secondary} icon="chevron-left" />
												</div>
												<div className="btn-padded">
													<Button
														state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
														isBusy={isBusy}
														onClick={() => {
															onSave();
														}}
														text={selectedLocation?.id ? "Update" : "Add"}
													/>
												</div>
											</div>
										</FormContainer>
									);
								}}
							</Form>
						</>
					) : (
						<>
							<ListItem
								icon="plus"
								onClick={() => {
									setSelectedLocation({
										name: "",
										medical_queue_indicator: false,
										show_in_covidpass: false,
										times: [],
									});
								}}
								text="Add New"
							/>
							{locations.map((itm) => {
								return (
									<ListItem
										onClick={() => {
											setSelectedLocation(itm);
										}}
										text={itm.name}
									/>
								);
							})}
						</>
					)}
				</React.Fragment>
			)}
		</>
	);
};

export default EditLocation;
