import * as React from "react";
import { FC, useEffect, Fragment } from "react";
import { useState } from "react";
import PersonApi, { ExtendedPersonProfile } from "../../api/controller/PersonApi";
import FluShotsApi from "../../api/controller/FluShotsApi";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";
import TestsApi from "../../api/controller/TestsApi";
import { ActionList, Button, ButtonType, Container, FormField, Alert, GridContainer, LayoutColumn, ButtonState, Text, TextType, Icon } from "@mit/hui";
import VaccineApi, { VaccineCourse, VaccineStatusResponse, VaccineQuestions } from "../../api/controller/VaccineApi";

const personApi = new PersonApi();
const fluShotApi = new FluShotsApi();
const vaccineApi = new VaccineApi();
const testsApi = new TestsApi();

interface PersonProfileComponentProps {
	// person: PersonProfile
}

const Component: FC<PersonProfileComponentProps> = () => {
	let { selectedPage, bodyRegions, selectedLocation, selectedLot, selectedCheckinPerson, selectedPerson, setSelectedPerson, setSuccessMessage, setUserId }: any = useAppContext();

	const [imageUrl, setImageUrl] = useState("");
	const [searchPersonExtended, setSearchPersonExtended] = useState<ExtendedPersonProfile | null>(null);
	const [vaccineStatus, setVaccineStatus] = useState<VaccineStatusResponse | null>(null);
	const [vaccineCourses, setVaccineCourses] = useState<VaccineCourse[] | null>(null);
	const [vaccineQuestions, setVaccineQuestions] = useState<VaccineQuestions[] | null>(null);
	const [isBusy, setBusy] = useState<boolean>(false);
	const [errorCfg, setErrorCfg] = useState<any>(null);
	const [confirmedMessage, setConfirmedMessage] = useState<boolean>(false);

	let selectedBodyRegion;
	let selectedShotNumber = 1;

	const [lastTestResult, setLastTestResult] = useState<string>("");

	useEffect(() => {
		setBusy(true);

		Promise.all([
			personApi.fetchPicture(selectedPerson.mitId),
			personApi.fetchProfileExtended(selectedPerson.kerberosId),
			testsApi.fetchTestResultsOnlyFluShots(selectedPerson.mitId),
			vaccineApi.fetchVaccineStatus(selectedPerson.mitId),
			vaccineApi.fetchVaccineCourse(selectedPerson.mitId),
			vaccineApi.fetchVaccineQuestions(selectedPerson.mitId),
		]).then(([url, extendedPerson, testResults, vaccineResult, vaccineCoursesResult, vaccineQuestionsResult]) => {
			setImageUrl(url);

			if (testResults.length > 0) {
				setLastTestResult(testResults[0].test_date);
			} else {
				setLastTestResult("");
			}
			setVaccineStatus(vaccineResult);
			if (vaccineResult.medical_messages && vaccineResult.medical_messages.length > 0) {
				setConfirmedMessage(true);
			}

			setVaccineCourses(vaccineCoursesResult);
			setSearchPersonExtended(extendedPerson);
			setVaccineQuestions(vaccineQuestionsResult);

			setBusy(false);
		});
	}, [selectedPerson]);

	const changeBodyRegion = (event: any) => {
		selectedBodyRegion = event.id;
	};

	const changeDoseSelection = (event: any) => {
		selectedShotNumber = event.dose_number;
	};

	const fetchStatus = () => {
		setBusy(true);
		vaccineApi.fetchVaccineStatus(selectedPerson.mitId).then((result) => {
			setVaccineStatus(result);
			setBusy(false);
		});
	};

	const fetchTestResults = () => {
		setBusy(true);
		testsApi.fetchTestResultsOnlyFluShots(selectedPerson.mitId).then((result) => {
			if (result.length > 0) {
				setLastTestResult(result[0].test_date);
			} else {
				setLastTestResult("");
			}
			setBusy(false);
		});
	};

	const getLastTestResult = () => {
		if (selectedPage === "Flu Shot" || selectedPage === "COVID Test") {
			if (lastTestResult && lastTestResult !== "") {
				return moment(lastTestResult).format("MMM D, yyyy");
			} else {
				return "None";
			}
		} else if (selectedPage === "Vaccine") {
			if (vaccineStatus?.course_progress && vaccineStatus.course_progress.length > 0 && vaccineCourses && vaccineCourses?.length > 0) {
				return moment(vaccineCourses[vaccineCourses?.length - 1].shot_date).format("MMM D, yyyy");
			} else {
				return "None";
			}
		}

		return "None";
	};

	const getCourseProgress = () => {
		if (selectedPage === "Vaccine") {
			if (vaccineStatus && vaccineStatus.status === "none") return <div className="alert alert-danger">Not elligable</div>;
			else
				return (
					<div className="row" style={{ justifyContent: "flex-end" }}>
						{vaccineStatus?.course_progress.map((itm) => {
							return (
								<div className="text-center mx-4">
									{itm.status === "completed" && (
										<div className="checked-ciricle">
											<div></div>
										</div>
									)}
									{itm.status === "incomplete" && (
										<div className="empty-ciricle">
											<div></div>
										</div>
									)}

									{moment(itm.shot_date).format("MMM D")}
								</div>
							);
						})}
					</div>
				);
		}

		if (selectedPage === "Flu Shot") {
			return <div></div>;
		}

		return <div></div>;
	};

	const getShotNumber = () => {
		if (vaccineStatus && vaccineStatus?.course_progress) {
			let incompleteShot = vaccineStatus?.course_progress.find((itm) => itm.status === "incomplete")?.shot_number;
			return incompleteShot;
		}

		return 1;
	};

	const checkShots = () => {
		return vaccineStatus && vaccineStatus.can_checkin === false;
	};

	const handleSubmit = () => {
		setBusy(true);
		setErrorCfg(null);
		if (selectedPage === "Flu Shot")
			fluShotApi
				.postFluShotsAttestation(
					selectedPerson.mitId,
					moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
					selectedBodyRegion ?? 1,
					selectedLocation.id,
					selectedCheckinPerson.mit_id,
					selectedLot.lot_number
				)
				.then(() => {
					setSuccessMessage("Flu Shot has been recorded for " + selectedPerson.displayName);

					setTimeout(() => {
						setSuccessMessage("");
					}, 3000);

					handleReset();
					fetchTestResults();
				});

		if (selectedPage === "Vaccine") {
			vaccineApi
				.postVaccineShot({
					body_region: selectedBodyRegion ?? 1,
					location_id: selectedLocation.id,
					lot_company: selectedLot.company,
					lot_number: selectedLot.lot_number,
					mit_id: selectedPerson.mitId,
					shot_date: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
					shot_number: selectedShotNumber || getShotNumber() || 1,
					test_by_mit_id: selectedCheckinPerson.mit_id,
					vaccine_course_guid: vaccineStatus?.course_guid,
				})
				.then(() => {
					setSuccessMessage("Vaccine Shot has been recorded for " + selectedPerson.displayName);

					setTimeout(() => {
						setSuccessMessage("");
					}, 3000);

					handleReset();
					fetchStatus();
				})
				.catch((e) => {
					setBusy(false);
					setErrorCfg({ msg: e.message, type: "error" });
				});
		}
	};

	const handleCancel = () => {
		setBusy(false);
		setUserId("");
		setSelectedPerson(null);
		setSuccessMessage("");
	};

	const handleReset = () => {
		setUserId("");
		setBusy(false);
		setSelectedPerson(null);
	};

	const responseColor = (value: boolean, reverse: boolean) => {
		if (value === true) {
			if (reverse) {
				return "green";
			} else {
				return "red";
			}
		} else if (value === false) {
			if (reverse) {
				return "red";
			} else {
				return "green";
			}
		}
	};

	const questionResponse = (value: boolean) => {
		if (value) {
			return "Yes";
		} else {
			return "No";
		}
	};
	const getDoseNumberDropdownOptions = (doses: number) => {
		const doseList: any = new Array(doses).fill("").map((o: any, i: number) => ({ icon: "", text: `Dose ${i + 1}`, dose_number: i + 1 }));

		doseList.push({
			icon: "",
			text: "BOOSTER",
			dose_number: -1,
		});
		return doseList;
	};

	const getBodyRegionsDropdown: any = () => {
		let dropdownItems = bodyRegions.map((itm) => {
			return {
				text: itm.name,
				icon: "",
				id: itm.id,
			};
		});

		return dropdownItems;
	};

	return (
		<div className="container">
			{isBusy && (
				<div className="p-3">
					<ActionList items={[]} isLoading />
				</div>
			)}
			{!isBusy && (
				<div>
					{selectedPage === "Vaccine" && vaccineStatus?.status_message && (
						<div className="pt-3">
							<Alert type={"error"} text={`${vaccineStatus.status_message}`} />
						</div>
					)}

					{errorCfg && <Alert type={errorCfg?.type} text={`${errorCfg?.msg}`} />}

					<div className="row p-3">
						<div className="col-4">
							<div className="user-picture">
								<div className="image-container">
									<img className={"img-object-large-fit"} src={imageUrl} alt={"profile"} />
								</div>
								<h3 className="mt-2 mb-2">{selectedPerson.displayName}</h3>
							</div>
						</div>
						<div className="col-8">
							<Container
								raised
								contents={
									<div>
										{confirmedMessage ? (
											<div className="p-4 user-view d-flex flex-column">
												<div className="d-flex justify-content-center image-icon p-2">
													<Icon icon={"exclamation-triangle"} type="regular" />
												</div>
												<div className="d-flex flex-column pt-2">
													{vaccineStatus?.medical_messages.map((x) => {
														return (
															<div className="d-flex justify-content-center">
																<Text content={x ?? ""} bold />
															</div>
														);
													})}
												</div>
												<div className="d-flex justify-content-center pt-2">
													<Button text="Continue" onClick={() => setConfirmedMessage(false)} />
												</div>
											</div>
										) : (
											<div className={"user-view p-4"}>
												{vaccineStatus && vaccineStatus?.can_checkin && !vaccineStatus?.eligible_bypass && (
													<>
														<div className="title">
															<Text content={"Take note of answers"} type={TextType.Body} bold />
														</div>
														{vaccineQuestions
															?.sort((a: any, b: any) => a.id - b.id)
															.map(({ id, answer, follow_up_multiple, text }: any) => (
																<GridContainer key={`question_id_${id}`} showGutters={true}>
																	<LayoutColumn colSize={11}>
																		<Text content={text} type={TextType.Body} bold />
																	</LayoutColumn>
																	<LayoutColumn colSize={1}>
																		<Text content={`${questionResponse(answer)}`} type={TextType.Body} color={responseColor(answer, follow_up_multiple)} bold />
																	</LayoutColumn>
																</GridContainer>
															))}
														<GridContainer showGutters={true}>
															<LayoutColumn alignment="left" colSize={12}>
																<hr />
															</LayoutColumn>
														</GridContainer>
													</>
												)}
												<FormField id="" labelDisplay="nextto" label="Date of Birth" value={searchPersonExtended?.date_of_birth} readonly editor={"textbox"} />
												<FormField id="" labelDisplay="nextto" label="Phone" value={searchPersonExtended?.phone} readonly editor={"textbox"} />
												<FormField id="" labelDisplay="nextto" label="Email" value={selectedPerson?.email} readonly editor={"textbox"} />
												<FormField
													id=""
													labelDisplay="nextto"
													label="Address"
													value={`${searchPersonExtended?.address.address1 ?? ""} ${searchPersonExtended?.address.city ?? ""} ${searchPersonExtended?.address.state ?? ""} ${
														searchPersonExtended?.address.postal_code ?? ""
													}`}
													readonly
													editor={"textbox"}
												/>
												{selectedPage === "Vaccine" && (
													<FormField id="" labelDisplay="nextto" label="Date Last Tested" value={getLastTestResult()} readonly editor={"textbox"} />
												)}
												{selectedPage === "Vaccine" && !vaccineStatus?.eligible_bypass && (
													<GridContainer showGutters={true}>
														<LayoutColumn colSize={4}>
															<div className="text-right">
																<label className="col-form-label">Course progress</label>
															</div>
														</LayoutColumn>
														<LayoutColumn colSize={8}>{getCourseProgress()}</LayoutColumn>
													</GridContainer>
												)}
												{selectedPage === "Vaccine" && vaccineStatus?.eligible_bypass && (
													<FormField
														id="dose-number"
														labelDisplay="nextto"
														label="Dose number"
														value={"Dose 1"}
														properties={{
															disabled: selectedPage === "Vaccine" && checkShots() ? true : false,
														}}
														onChange={changeDoseSelection}
														options={getDoseNumberDropdownOptions(vaccineStatus?.number_of_shots ? parseInt(vaccineStatus?.number_of_shots) : 2)}
														editor={"dropdown"}
													/>
												)}

												<FormField
													id=""
													labelDisplay="nextto"
													label="Administered today"
													value={"Left Shoulder"}
													properties={{
														disabled: selectedPage === "Vaccine" && checkShots() ? true : false,
													}}
													onChange={changeBodyRegion}
													options={getBodyRegionsDropdown()}
													editor={"dropdown"}
												/>
												<div className="btn-padded text-right mt-3">
													<Button icon="chevron-left" text="" type={ButtonType.Secondary | ButtonType.Ghost} onClick={() => handleCancel()} />
													{selectedPage === "Vaccine" && (
														<Fragment>
															{vaccineStatus?.can_checkin && (
																// {vaccineStatus && vaccineStatus?.status !== "none" && vaccineStatus?.status !== "consent_needed" && (
																<Fragment>
																	<Button
																		icon="check"
																		state={checkShots() ? ButtonState.Disabled : ButtonState.Enabled}
																		text="Record shot"
																		type={ButtonType.Primary}
																		onClick={() => handleSubmit()}
																	/>
																</Fragment>
															)}
														</Fragment>
													)}
													{selectedPage === "Flu Shot" && <Button icon="check" text="Record shot" type={ButtonType.Primary} onClick={() => handleSubmit()} />}
												</div>
											</div>
										)}
									</div>
								}
								alignment={"left"}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Component;
