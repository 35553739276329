import BaseController from './BaseController';

export interface MedicalFluShotResult {
    test_guid: string;
    test_date: string;
    result: string;
}


export default class TestsApi extends BaseController {
    readonly url_fetch_results: string;

    constructor() {
        super()
        this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results`;
    }


    async fetchTestResultsOnlyFluShots(mitId: string): Promise<MedicalFluShotResult[] | []> {

        let response = await this.useFetch('GET', 'fetchTestResultsOnlyFluShots', this.url_fetch_results+`?mit_id=${mitId}&optional=flu_shots`);
        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async fetchTestResultsOnlyVaccineShots(mitId: string): Promise<MedicalFluShotResult[] | []> {

        let response = await this.useFetch('GET', 'fetchTestResultsOnlyFluVaccineShots', this.url_fetch_results+`?mit_id=${mitId}&optional=vaccine`);
        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }


}
